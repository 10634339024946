import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import {
  Grid,
  TextField,
  Typography,
  MenuItem
} from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { isEqual } from "lodash";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import ContractService from "../../../../services/ContractService";
import apiClient from "../../../../auth/apiClient";
import * as c from "../../../../constants";
import ChargedPaymentMethodForm from "./ChargedPaymentMethodForm/ChargedPaymentMethodForm";
import CreditCardOnFilePaymentMethodForm from "./CreditCardOnFilePaymentMethodForm/CreditCardOnFilePaymentMethodForm";
import { useFlags } from "launchdarkly-react-client-sdk";

const contractService = new ContractService(apiClient);

const FlexibleParkingAccountForm = ({
  children,
  contractID,
  facilityID,
  disabled,
  paymentMethod,
  handlePayMentMethodChange,
  contractInfo,
  updateContractInfo
}) => {
  const {
    flexibleParkingCreditCardOnFile: creditCardOnFileFlag
  } = useFlags();
  const [isPaymentMethodChange, setIsPaymentMethodChange] = useState(false);
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const padNumericValues = (obj) => {
    Object.entries(obj).forEach(([key, val]) => {
      console.dir(parseFloat(val));
      if (key == "paymentMethod") return;
      if (!isNaN(parseFloat(val))) {
        const nonPaddedVal = `${val}`;
        if (nonPaddedVal.includes(".")) {
          const [,afterDecimal] = nonPaddedVal.split(".");
          if (afterDecimal.length == 1) {
            obj[key] = nonPaddedVal + "0";
          } else if (afterDecimal.length == 0) {
            obj[key] = nonPaddedVal + "00";
          }
        } else {
          obj[key] = nonPaddedVal + ".00";
        }
      }
    });
  }

  const FPASchema = Yup.object().shape({
    paymentMethod: Yup.number()
      .oneOf([...Object.values(c.FPA_PAYMENT_METHODS)]),
    threshold: Yup.number()
      .typeError("Must be a number")
      .min(0, "Must be positive")
      .required("Required")
      .when("paymentMethod", {
        is: (paymentMethod) => !paymentMethodIsCharged(paymentMethod),
        then: Yup.number().notRequired(),
      }),
    autoRecharge: Yup.boolean()
    .when("paymentMethod", {
      is: (paymentMethod) => !paymentMethodIsCharged(paymentMethod),
      then: Yup.boolean().notRequired(),
    }),
    rechargeAmount: Yup.number().when("autoRecharge", {
      is: (autoRecharge) => autoRecharge,
      then: Yup.number()
        .typeError("Must be a number")
        .min(0, "Must be positive")
        .required("Required"),
      otherwise: Yup.number().nullable(),
    }).when("paymentMethod", {
      is: (paymentMethod) => !paymentMethodIsCharged(paymentMethod),
      then: Yup.number().notRequired(),
    }),
    showFee: Yup.boolean()
      .when("paymentMethod", {
        is: (paymentMethod) => !paymentMethodIsCharged(paymentMethod),
        then: Yup.boolean().notRequired(),
      }),
    showBalance: Yup.boolean(),
    allowNegative: Yup.boolean()
      .when("paymentMethod", {
        is: (paymentMethod) => !paymentMethodIsCharged(paymentMethod),
        then: Yup.boolean().notRequired(),
      }),
    negativeLimit: Yup.number().when("allowNegative", {
      is: (allowNegative) => allowNegative,
      then: Yup.number()
        .typeError("Must be a number")
        .min(0, "Enter as a positive number")
        .required("Required"),
      otherwise: Yup.number().nullable(),
    }).when("paymentMethod", {
      is: (paymentMethod) => !paymentMethodIsCharged(paymentMethod),
      then: Yup.number().nullable().optional(),
    }),
    emailReceipts: Yup.boolean().when("paymentMethod", {
      is: (paymentMethod) => paymentMethodIsCharged(paymentMethod),
      then: Yup.boolean().notRequired(),
    })
  });

  const defaultValues = {
    paymentMethod: c.FPA_PAYMENT_METHODS["CHARGE_FPA"],
    threshold: "0.00",
    autoRecharge: false,
    rechargeAmount: "0.00",
    showFee: false,
    showBalance: false,
    allowNegative: false,
    negativeLimit: "0.00",
    emailReceipts: false
  };

  const paymentMethodIsCharged = (paymentMethodTmp) => {
    return (!paymentMethodTmp || paymentMethodTmp === c.FPA_PAYMENT_METHODS["CHARGE_FPA"])
  }

  const methods = useForm({
    resolver: yupResolver(FPASchema),
    defaultValues: contractInfo?.payload ? contractInfo.payload : defaultValues,
    mode: "onChange",
  });

  const {
    control,
    resetField,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isDirty, isValid, errors },
  } = methods;

  const createOrUpdateContractDetails = async (values) => {
    const isCreateDetails = contractInfo?.contractDetailsID ? false : true;
    try {
      let response;

      if (isCreateDetails) {
        response = await contractService.createContractDetails(
          facilityID,
          contractID,
          c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          {
            payload: values,
            contractID: contractID,
            typeID: c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          }
        );
      } else {
        response = await contractService.updateContractDetails(
          facilityID,
          contractID,
          c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          {
            payload: values,
            contractID: contractID,
            typeID: c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          }
        );
      }

      if (response?.data?.payload) {
        updateContractInfo(response.data);
      }
    } catch {
      enqueueSnackbar(
        `Failed to  ${
          isCreateDetails ? "create" : "update"
        } flexible account details`,
        {
          variant: "error",
          tag: `${isCreateDetails ? "create" : "update"}ContractDetailsError`,
        }
      );
    }
  };

  const handleBlur = () => {
    const formValues = getValues();

    if (!creditCardOnFileFlag || paymentMethodIsCharged(paymentMethod) || isPaymentMethodChange) {
      padNumericValues(formValues);
      setValue("threshold", formValues.threshold);
      setValue("negativeLimit", formValues.negativeLimit);
      setValue("rechargeAmount", formValues.rechargeAmount);
      formValues.threshold = parseFloat(getValues("threshold"));
      formValues.negativeLimit = parseFloat(getValues("negativeLimit"));
      formValues.rechargeAmount = parseFloat(getValues("rechargeAmount"));
    }

    const currentValues = contractInfo?.payload ?? defaultValues;
    if ((isPaymentMethodChange && isValid && !isEqual(currentValues, formValues)) || (isDirty && isValid && !isEqual(currentValues, formValues))) {
      createOrUpdateContractDetails(formValues);
    }
  };

  const handlePayMentMethodChanges = (event) => {
    handlePayMentMethodChange(event);
    setValue("paymentMethod", event.target.value);
    setIsPaymentMethodChange(true);

    if(event.target.value === c.FPA_PAYMENT_METHODS["CHARGE_FPA"]) {
      const formValues = getValues();

      padNumericValues(formValues)
    }
  }

  useEffect(() => {
    if (contractInfo?.payload) {
      if (!creditCardOnFileFlag || paymentMethodIsCharged(contractInfo?.payload.paymentMethod)) {
        padNumericValues(contractInfo?.payload);
      }

      let formDefaultValues = contractInfo?.payload;

      if (!creditCardOnFileFlag && !paymentMethodIsCharged(contractInfo?.payload.paymentMethod)) {
        formDefaultValues.paymentMethod = c.FPA_PAYMENT_METHODS["CHARGE_FPA"]
      }
      reset(formDefaultValues);
    }
  }, [contractInfo?.payload])

  return (
    <div className={clsx("card-content", classes.contentRoot)}>
      {creditCardOnFileFlag && (
        <Grid item className={clsx("card-content", classes.payContainer)} spacing={2}>
          <Grid container item xs={12} lg={8} xl={4} spacing={2}>
            <Grid item xs={12} lg={10} xl={12} spacing={2}>
              <Controller
                  name="paymentMethod"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      data-id="payment-method"
                      id="payment-method"
                      name="payment-method"
                      className={clsx(
                        "payType",
                        classes.payType,
                        classes.flexInput
                      )}
                      label={<Typography>Payment Method</Typography>}
                      select
                      disabled={disabled}
                      variant="outlined"
                      SelectProps={{
                        SelectDisplayProps: { "data-testid": "payment-method" }
                      }}
                      style={
                        { width: "100%" }
                      } 
                      onChange={(e) =>
                        handlePayMentMethodChanges(e)
                      }
                      onBlur={handleBlur}
                      defaultValue={field.value ?? c.FPA_PAYMENT_METHODS["CHARGE_FPA"]}
                      value={field.value ?? c.FPA_PAYMENT_METHODS["CHARGE_FPA"]}
                      error={errors && errors["paymentMethod"] ? true : false}
                      helperText={
                        (errors && errors["paymentMethod"]) ||
                        "when exiting"
                      }
                    >
                      <MenuItem value={c.FPA_PAYMENT_METHODS["CHARGE_FPA"]}>
                        Charge Flexible Parking Account{" "}
                      </MenuItem>
                      <MenuItem value={c.FPA_PAYMENT_METHODS["CHARGE_CCOF"]}>
                        Charge Credit Card on File{" "}
                      </MenuItem>
                    </TextField>
                  )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={4} xl={8} spacing={2}>
            <Grid item xs={12} lg={12} spacing={2}></Grid>      
          </Grid>
        </Grid>
      )}

      {(!creditCardOnFileFlag || !paymentMethod || paymentMethod === c.FPA_PAYMENT_METHODS["CHARGE_FPA"]) && (
        <ChargedPaymentMethodForm
          disabled={disabled}
          formControl={control}
          watch={watch}
          resetField={resetField}
          setValue={setValue}
          errors={errors}
          handleBlur={handleBlur}
        />
      )}
      {creditCardOnFileFlag && paymentMethod === c.FPA_PAYMENT_METHODS["CHARGE_CCOF"] && (
        <CreditCardOnFilePaymentMethodForm
          disabled={disabled}
          formControl={control}
          watch={watch}
          resetField={resetField}
          handleBlur={handleBlur}
        />
      )}
      {children}
    </div>
  );
};

FlexibleParkingAccountForm.propTypes = {
  children: PropTypes.node,
  contractID: PropTypes.string,
  facilityID: PropTypes.string,
};

export default FlexibleParkingAccountForm;
